import { WhatsApp } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import { CTA } from './CTA'
import { useSelector } from 'react-redux'




export const Footer = () => {
    const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
    const { categoryList } = useSelector((state) => state.commonApiData.getCategoryCart)

    return (
        <>
            <CTA />

            <footer>
            <div className="container">
                <div className="foot-icons py-5 border-1 border-bottom">
                    <div className="row">
                        <div className="col-lg-3 mb-3 mb-md-0 col-6 text-center border-1 border-end">
                            <span className="me-3"><img src="./images/icons/shipping.svg" width="35" alt="" /></span>
                            <p className="m-0 mt-2"> Free Shipping over Rs 200  </p>
                        </div>
                        <div className="col-lg-3 mb-3 mb-md-0 col-6 text-center border-1 border-end">
                            <span className="me-3"><img src="./images/icons/return.svg" width="35" alt="" /></span>
                            <p className="m-0 mt-2 ">  Cancellation after 1 day  </p>
                        </div>
                        <div className="col-lg-3 col-6 text-center border-1 border-end">
                            <span className="me-3"><img src="./images/icons/secure.svg" width="35" alt="" /> </span>
                            <p className="m-0 mt-2 ">  100% Secure Payment  </p>
                        </div>
                        <div className="col-lg-3 col-6 text-center">
                            <span className="me-3"><img src="./images/icons/24-hour.svg" width="35" alt="" /> </span>
                            <p className="m-0 mt-2 ">  24/7 Dedicated Support  </p>
                        </div>
                    </div>
                </div>
                <div className="foot-content">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="foot-info">
                                <h6 className="fw-bold fs-3 text-theme text-capitalize">indocraftworld.com</h6>
                                {/* <p>Founded with a vision to promote “Vocal for Local” campaign and to transform and scale up the entire structure of the business for our people deeply involved with Arts and Crafts of India</p> */}
                                <span className='my-2 fs-6 fw-bold d-block'>Experience Our App On Mobile</span>
                                    <div className="d-flex">
                                        <a
                                            className='mx-3 text-dark text-decoration-none small'
                                            href="https://play.google.com/store/apps/details?id=com.sipl.indo_craft_world"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src="./images/google_play.png" className='w-100' height='60' alt="" />
                                        </a>
                                        <a
                                            className='mx-3 text-dark text-decoration-none small'
                                            href="https://apps.apple.com/in/app/indo-craftworld/id6670483738"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src="./images/apple_store.png" className='w-100' height='60' alt="" />
                                        </a>
                                </div>
                            </div>
                        </div>  
                        <div className="col-lg-2 ">
                            <div className="links-item mt-5 mt-lg-0">
                                <h6 style={{
                                    margin: " 10px 0px"
                                }} className="fsz-18 "> Company </h6>
                                <ul className="links">
                                    <li> <Link to="/about-us"> About</Link> </li>
                                    <li> <Link to="/contact"> Contact</Link> </li>
                                {/*    <li> <Link to="/career">Career </Link> </li>*/}
                                    <li> <Link to="/blogs"> Blog </Link> </li>
                                    {/*<li> <Link to="/sitemap"> Sitemap </Link> </li>*/}
                                    {/*<li> <Link to="/store-location"> Store Locations </Link> </li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="links-item mt-5 mt-lg-0">
                                <h6 style={{
                                    margin: " 10px 0px"
                                }} className="fsz-18"> Help Center </h6>
                                <ul className="links">
                                    <li> <Link to="/cancellation-policy"> Cancellation Policy</Link> </li>
                                    <li> <Link to="/privacy-policy"> Privacy Policy</Link> </li>
                                    <li> <Link to="/return-policy"> Return and Refund Policy </Link> </li>
                                    <li> <Link to="/terms-conditions"> Terms & Conditions </Link> </li>
                                    <li> <Link to="/faqs"> FAQs </Link> </li>
                                    <li><Link to='/profile'>  My Account  </Link> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <small className="fsz-12 text-uppercase"> hotline 24/7 </small>
                            <h5 className="fw-bold color-blue1 d-flex align-items-center mt-2"><WhatsApp sx={{ mr: '5px' }} color='success' /> 9971930718 </h5>
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <i className="fa-regular fa-envelope text-theme"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                        <a className='text-decoration-none text-dark mb-2' href="mailto:icw@indocraftworld.com"> icw@indocraftworld.com</a>
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div className="flex-shrink-0">
                                    <i className="fa-solid fa-location-dot text-theme"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <p className='small'>BASEMENT, KH NO-101, BLOCK-L, MAHIPALPUR EXTN, New Delhi, New Delhi, Delhi, 110037</p>
                                </div>
                            </div>
                            <div id="socialMediaicons">
                                <a href="#" className='me-2'><img src="./images/instagram.png" /></a>
                                <a href="#" className='me-2'><img src="./images/facebook.png" /></a>
                                <a href="#" className='me-2'><img src="./images/linkedin.png" /></a>
                                <a href="#" className='me-2'><img src="./images/twitter.png" width='30' /></a>
                            </div>
                        </div>
                        <div className="col-12 mt-4 footer_about border-top">
                            <div className="accordion mt-2" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header bg-light" id="headingabout">
                                            <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseabout" aria-expanded="true" aria-controls="collapseabout">
                                            More About Indocraft World
                                        </button>
                                    </h2>
                                    <div id="collapseabout" className="accordion-collapse collapse" aria-labelledby="headingabout" data-bs-parent="#accordionExample" >
                                        <div className="accordion-body">
                                                <h4>Welcome to Indo Craft World - Find Unique Handicrafts Products Online</h4>
                                                <p>Welcome to Indo Craft World, where you can find real and truly made-by-hand work products from different parts of India. Here on our platform, you’ll find a large selection of traditional Indian crafts ranging from exquisite fabrics, wooden and terracotta, ceramic handcrafted items, pottery, and many others. Buying should not be a chore; Shop from the comfort of your home while immersing yourself in India’s cultural diversity.</p>

                                                <h4>Why Choose Indian Handicrafts?</h4>
                                                <h6>1. Heritage and Tradition</h6>
                                                <p>Indian handicrafts are a reflection of centuries-old traditions. Each piece tells a story, whether it's handwoven textiles, intricately carved woodwork, or hand-painted pottery.</p>
                                                <h6>2. Unique Handmade Products</h6>
                                                <p>Unlike mass-produced items, handmade products are one-of-a-kind. When you buy handicraft items, you own something unique that embodies the love and skill of the artisan.</p>
                                                <h6>3. Eco-Friendly and Sustainable</h6>
                                                <p>Many handicraft products are made from natural, eco-friendly materials. By choosing Indian handicrafts, you’re also contributing to sustainability.</p> 
                                                <h4>Shop Indian Handicrafts Online</h4>
                                                <p>Now, one can easily get original Indian handicraft items online than ever. Our e-commerce site deals with the sale of a variety of select products in handmade crafts from all over the country. If you are in search of home décor accessories, jewelry items, textile products, or procuring Indian art & craft collectibles, Townaires Indian handicrafts online shopping solutions are easy and efficient.</p>
                                                <h4>What We Offer:</h4>
                                                <ul className="ps-4" style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                        <b>Traditional Indian Crafts :</b>From pottery to wooden sculptures, find unique crafts that carry the soul of India.
                                                    </li>
                                                    <li>
                                                        <b>Handicraft Products for Every Taste :</b>Explore a wide range of products that suit various styles, whether you're looking for something modern or traditional.

                                                    </li>
                                                    <li>
                                                        <b>Handmade Gifts: </b>Perfect for special occasions, handmade products add a personal touch to your gifts.
                                                    </li>
                                                </ul>
                                                <p></p>
                                                <h4>Why Buy from Indo-Craft World?</h4>
                                                <p></p>
                                                <h6>1. Authentic Craftsmanship</h6>
                                                <p>Our products are sourced directly from artisans, ensuring you receive authentic <b>Indian handicrafts</b> that carry the true essence of Indian tradition.</p>
                                                <h6>2. Support Local Artisans</h6>
                                                <p>When you shop with us, you're not just buying a product, but supporting the livelihoods of artisans who have kept India's traditional crafts alive for generations.</p>
                                                <h6>3. Hassle-Free Online Shopping</h6>
                                                <p>With a user-friendly interface, we make Indian handicrafts online shopping easy and enjoyable. Browse through our diverse collection and find the perfect handicraft items for your home or gifting needs.</p>
                                                <h4>Explore Our Handicraft Collection Today!</h4>
                                                <p>Discover the beauty of Indian handicrafts and take home a piece of India's rich culture. Whether it's a handcrafted pottery item or a beautifully woven textile, each product in our collection is a masterpiece.</p>

                                                <p>Go shopping right now and make the beautiful experience of traditional Indian craftsmanship a part of your life!</p>

                                            <Link to='/'><h6 style={{fontSize:'18px'}} className='text-theme'>Online Shopping</h6></Link>

                                            <ul className="links li-cat p-0">
                                                    {
                                                        categoryList?.map((category, index) => (
                                                            <li key={index} className=''>
                                                            <Link to={`/products?category=${category.categoryName}`} style={{ fontSize: '13px' }} className='mdi_heading text-dark fw-bold footer_Link'>{category.categoryName}</Link>
                                                            <div className="d-flex align-items-center">
                                                                {
                                                                    category?.subCategoryList?.map((subCategory) => (
                                                                        <Link to={`/products?subCategory=${subCategory.subCategoryName}`} className='me-2 text-theme fw-medium'>{subCategory.subCategoryName} <span>&nbsp; | </span></Link>
                                                                    ))
                                                                }
                                                            </div>
                                                        </li>
                                                    ))
                                                }                                                   
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </footer>
            <div className="foot_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 text-center text-lg-start my-auto d-flex gap-2">
                            <Link to="/sellerRegistration" className="br-20 fw-bold text-theme text-decoration-none becomeseller">Become a Seller</Link>
                            <Link className='br-20 fw-bold text-theme text-decoration-none becomeseller' style={{ cursor: "pointer" }}
                                onClick={() => window.open('https://seller.indocraftworld.com/', '_blank')}
                            >Seller Login</Link>
                        </div>
                        <div className="col-lg-3 text-center my-auto">
                            <p className="m-0 text-black-50"> © 2024 <Link to="/" className="fw-bold text-theme text-decoration-none opacity-50"> Indocraft </Link>. All Rights Reserved </p>
                        </div>
                        <div className="col-lg-3 text-center my-auto">
                            <p className="m-0 text-black-50"> Powered By: <Link to="https://www.sagarinfotech.com/" target='_blank' className="fw-bold text-theme text-decoration-none opacity-50"> Sagar informatics Pvt. Ltd.</Link></p>
                        </div>
                        <div className="col-lg-3">
                            <div className="pay text-center my-4 my-lg-0">
                                <img src="./images/payment-icons.png" alt="" className="mx-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
